import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import defaultTheme from 'base/theme_default';
import Router from 'base/Router';
import { Provider } from 'react-redux';
import store from 'base/store';
import 'typeface-roboto';


class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<MuiThemeProvider theme={defaultTheme}>
					<CssBaseline />
					<Router />
				</MuiThemeProvider>
			</Provider>
		);
	}
}



export default App;
