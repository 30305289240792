import React, { PureComponent } from 'react';
//import SetTitle from 'actionComponents/SetTitle';



class HomeTwo extends PureComponent {
	render() {
		return (
			<div>
				{/*<SetTitle title={"fite me"} />*/}
				Test  page
			</div>
		);
	}
}



export default HomeTwo;