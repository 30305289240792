import React, { PureComponent } from 'react';
import menuTheme from 'base/theme_menu';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Home from '@material-ui/icons/Home';
import Done from '@material-ui/icons/Done';
import { actions } from "base/store";
import { connect } from "react-redux";
import { push } from 'connected-react-router'



const styles = theme => ({
	root: {
		background: '#323232',
		height: '100%',
		overflowX: 'hidden',
	},
});



class Navigation extends PureComponent {

	setRoute = (url) => e => {
		this.props.push(url)
	};


	render() {
		const { classes } = this.props;

		return (
			<MuiThemeProvider theme={menuTheme}>
				<div
					className={classes.root}
					onMouseEnter={this.props.menuOpenTemporary}
					onMouseLeave={this.props.menuCloseTemporary}
				>
					<List>
						<ListItem button onClick={this.setRoute('/')}>
							<ListItemIcon><Home /></ListItemIcon>
							<ListItemText primary={'Home'} />
						</ListItem>
						<ListItem button onClick={this.setRoute('/test')}>
							<ListItemIcon><Done /></ListItemIcon>
							<ListItemText primary={'Test'} />
						</ListItem>
					</List>
				</div>
			</MuiThemeProvider>
		)
	}
}



const mapDispatchToProps = dispatch => ({
	menuOpenTemporary: () => dispatch(actions.layout.menuOpenTemporary()),
	menuCloseTemporary: () => dispatch(actions.layout.menuCloseTemporary()),
	push: (url) => dispatch(push(url)),
});


export default connect(null, mapDispatchToProps)(withStyles(styles)(Navigation));