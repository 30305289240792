import React from 'react';
import DashboardCard from "components/DashboardCard";
import CalendarToday from '@material-ui/icons/CalendarToday';
import PropTypes from 'prop-types';



const RecentVisits = React.memo((props) => (
	<DashboardCard
		color={'deepOrange'}
		icon={<CalendarToday />}
		topLabel="22 in the last 7 days"
		topContent={"Visits"}
		size={props.size}
	>
		Hooray!
	</DashboardCard>
));



RecentVisits.defaultProps = {
	size: 'sm',
};

RecentVisits.propTypes = {
	size: PropTypes.oneOf([ 'xs', 'sm', 'md', 'lg', 'xl' ]).isRequired,
};


export default RecentVisits;