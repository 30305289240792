import React, { PureComponent } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Storage from 'dashboards/Storage';
import RecentVisits from 'dashboards/RecentVisits';
import RecentReports from 'dashboards/RecentReports';
import RecentPatients from 'dashboards/RecentPatients';
import Announcement from 'components/Announcement';



const styles = theme => ({
	flexContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		boxSizing: 'border-box',
		width: '100%',
	},
});



class Home extends PureComponent {
	render() {
		const { classes } = this.props;

		return (
			<div>
				<div className={classes.flexContainer}>
					<Announcement
						variant="success"
						size="lg"
						headerContent={
							<Typography variant="h6" color="inherit" align="left">Pardon our dust</Typography>
						}
					>
						<Typography gutterBottom>
							Thank you for signing up for Sabertooth Charting! We're still in beta, and your account is
							free we exit the beta stage - currently planned for the first half of 2019. You'll get an
							email 30 days before we're ready to go live to let you know what you need to do to keep your
							account active. If you see a message in a big colored box like this on other pages, that
							means we're actively investigating issues and making changes.<br /><br />
						</Typography>

						<Typography gutterBottom>
							We have a lot of improvements and additions in the works. Some issues will be readily
							apparent, while some features we hope you discover as we work and wonder how you lived
							without them. If you have any questions or comments, please don't hesitate to get ahold of
							us by emailing <strong>support@sabertoothcharting.com</strong>. We'll provide frequent
							progress updates on the homepage. Soon, we plan to make this into a dashboard showing all of
							your recent activity, so you can get to the info you need quickly.<br /><br />
						</Typography>

						<Typography gutterBottom>
							This has been a nearly two year long labor of love for us, and we're so glad you've joined
							us. Thank you from all of us at Sabertooth Charting.
						</Typography>
					</Announcement>

					<Announcement
						variant="blueGrey"
						size="lg"
						headerContent={
							<Typography variant="h6" color="inherit" align="left">Welcome!</Typography>
						}
					>
						<Typography gutterBottom>
							Welcome to the Sabertooth Charting beta! We're working on a lot of features and
							quality-of-life improvements, but we're ready to let the world start using what we've been
							working on.<br /><br />

							As a general rule-of-thumb, you should not rely solely on beta software. There will be bugs
							(which we hope to fix quickly), there may be downtime (but hopefully so short that you never
							notice), and there are features still being added and refined constantly. We're dedicated to
							making sure all data carries through when we leave beta.<br /><br />

							If you encounter any issues, please send an email to support@sabertoothcharting.com. Please
							include as many details as possible when reporting an issue. Note that we may not be able to
							respond to all bug reports, but we read all of them and will do our best to fix issues in a
							timely manner. We also have a beta FAQ page available in the menu, or by clicking here.

							To get started, click the menu icon in the top left corner of the screen.
						</Typography>
					</Announcement>

					<RecentPatients />
					<RecentVisits />
					<RecentReports />
					<Storage />


				</div>
			</div>
		);
	}
}



export default withStyles(styles)(Home);