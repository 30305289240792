import React from 'react';
import DashboardCard from "components/DashboardCard";
import Chart from '@material-ui/icons/InsertChart';
import PropTypes from 'prop-types';



const RecentReports = React.memo((props) => (
	<DashboardCard
		color={'amber'}
		icon={<Chart />}
		topContent={"Reports"}
		topLabel="15 in the last 7 days"
		size={props.size}
	>
		Hooray!
	</DashboardCard>
));



RecentReports.defaultProps = {
	size: 'sm',
};

RecentReports.propTypes = {
	size: PropTypes.oneOf([ 'xs', 'sm', 'md', 'lg', 'xl' ]).isRequired,
};


export default RecentReports;