import React, { PureComponent } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { actions } from 'base/store';
import AppSearchResults from './AppSearchResults';



const styles = theme => ({
	search: {
		position: 'relative',
		border: `1px solid`,
		borderColor: fade(theme.palette.grey[ 500 ], 0.25),
		borderRadius: theme.shape.borderRadius * 8,
		backgroundColor: fade(theme.palette.grey[ 100 ], 0.25),
		'&:hover': {
			backgroundColor: fade(theme.palette.grey[ 100 ], 0.75),
			borderColor: fade(theme.palette.grey[ 500 ], 0.50),
		},
		marginRight: theme.spacing.unit * 2,
		marginLeft: 0,
		width: '100%',
		[ theme.breakpoints.up('sm') ]: {
			marginLeft: theme.spacing.unit * 3,
			width: 200,
		},
	},
	searchIcon: {
		width: theme.spacing.unit * 6,
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		opacity: 0.5,
	},
	inputRoot: {
		color: 'inherit',
		width: '100%',
	},
	inputInput: {
		paddingTop: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
		paddingBottom: theme.spacing.unit,
		paddingLeft: theme.spacing.unit * 6,
		transition: theme.transitions.create('width'),
		width: '100%',
		[ theme.breakpoints.up('md') ]: {
			width: 200,
		},
	},
});



class AppSearch extends PureComponent {

	anchor = React.createRef();


	render() {
		const { classes, appSearchTerm, setSearchTerm } = this.props;

		return (
			<React.Fragment>
				<div className={classes.search} ref={this.anchor}>
					<div className={classes.searchIcon}>
						<SearchIcon />
					</div>
					<InputBase
						placeholder="Search…"
						value={appSearchTerm}
						onChange={setSearchTerm}
						classes={{
							root: classes.inputRoot,
							input: classes.inputInput,
						}}
					/>
				</div>
				<AppSearchResults anchor={this.anchor} />
			</React.Fragment>
		);
	}
}



const mapDispatchToProps = dispatch => ({
	setLayoutState: (data) => dispatch(actions.layout.setState(data)),
	setSearchTerm: (e) => dispatch(actions.layout.setSearchTerm(e)),
});


const mapStateToProps = state => ({
	appSearchTerm: state.layout.appSearchTerm,
});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppSearch));