import React from 'react';
import DashboardCard from "components/DashboardCard";
import PropTypes from 'prop-types';



const Announcement = React.memo((props) => (
	<DashboardCard
		color={getColor(props.variant)}
		headerContent={props.headerContent}
		size={props.size}
		variant="full"
	>
		{props.children}
	</DashboardCard>
));



function getColor(variant) {
	switch(variant) {
		case 'success':
			return 'lightGreen';
		case 'warning':
			return 'amber';
		case 'danger':
		case 'error':
			return 'red';
		case 'blue':
			return 'blue';
		case 'blueGrey':
			return 'blueGrey';
		default:
			return 'grey';
	}
}


Announcement.defaultProps = {
	size: 'xl',
	variant: 'default',
	headerContent: '',
};

Announcement.propTypes = {
	size: PropTypes.oneOf([ 'xs', 'sm', 'md', 'lg', 'xl' ]).isRequired,
	variant: PropTypes.oneOf([ 'default', 'success', 'warning', 'danger', 'error', 'blue', 'blueGrey' ]).isRequired,
	headerContent: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]).isRequired,
};


export default Announcement;