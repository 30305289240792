import React, { memo } from 'react';
import { withStyles } from "@material-ui/core/styles";
import classnames from 'classnames';
import PropTypes from 'prop-types';
import DashboardCardIconHeader from './DashboardCardIconHeader';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';
import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import deepPurple from '@material-ui/core/colors/deepPurple';
import lightBlue from '@material-ui/core/colors/lightBlue';
import cyan from '@material-ui/core/colors/cyan';
import teal from '@material-ui/core/colors/teal';
import green from '@material-ui/core/colors/green';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lime from '@material-ui/core/colors/lime';
import yellow from '@material-ui/core/colors/yellow';
import amber from '@material-ui/core/colors/amber';
import deepOrange from '@material-ui/core/colors/deepOrange';
import brown from '@material-ui/core/colors/brown';
import blueGrey from '@material-ui/core/colors/blueGrey';




const styles = theme => ({
	container: {
		padding: `0 15px !important`,
		flexGrow: 0,
		margin: 0,
		boxSizing: 'border-box',
	},
	containerXSmall: {
		maxWidth: '20%',
		flexBasis: '20%',
		[ theme.breakpoints.only('xs') ]: {
			maxWidth: '100%',
			flexBasis: '100%',
		},
		[ theme.breakpoints.only('sm') ]: {
			maxWidth: '50%',
			flexBasis: '50%',
		},
		[ theme.breakpoints.only('md') ]: {
			maxWidth: 'calc(100% / 3)',
			flexBasis: 'calc(100% / 3)',
		},
		[ theme.breakpoints.only('lg') ]: {
			maxWidth: '25%',
			flexBasis: '25%',
		},
	},
	containerSmall: {
		maxWidth: '25%',
		flexBasis: '25%',
		[ theme.breakpoints.down('sm') ]: {
			maxWidth: '100%',
			flexBasis: '100%',
		},
		[ theme.breakpoints.only('md') ]: {
			maxWidth: 'calc(100% / 3)',
			flexBasis: 'calc(100% / 3)',
		},
	},
	containerMedium: {
		maxWidth: 'calc(100% / 3)',
		flexBasis: 'calc(100% / 3)',
		[ theme.breakpoints.down('sm') ]: {
			maxWidth: '100%',
			flexBasis: '100%',
		},
		[ theme.breakpoints.only('md') ]: {
			maxWidth: '50%',
			flexBasis: '50%',
		},
	},
	containerLarge: {
		maxWidth: '50%',
		flexBasis: '50%',
		[ theme.breakpoints.down('md') ]: {
			maxWidth: '100%',
			flexBasis: '100%',
		},
	},
	containerXLarge: {
		maxWidth: '100%',
		flexBasis: '100%',
	},
	innerContainer: {
		color: 'rgba(0, 0, 0, 0.87)',
		width: '100%',
		border: 0,
		display: 'flex',
		position: 'relative',
		minWidth: 0,
		wordWrap: 'break-word',
		fontSize: '.875rem',
		marginTop: 30,
		marginBottom: 30,
		background: '#ffffff',
		boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
		borderRadius: 6,
		flexDirection: 'column',
	},
	topContentRightAlign: {
		textAlign: 'right',
		background: 'transparent',
		boxShadow: 'none',
		color: '#ffffff',
		margin: '0 15px',
		padding: 0,
		position: 'relative',
		'&:first-child': {
			borderRadius: 'calc(.25rem - 1px) calc(.25rem - 1px) 0 0',
		},
	},
	topContentRightAlignLabel: {
		color: '#999999',
		margin: 0,
		fontSize: 14,
		marginTop: 0,
		marginBottom: 0,
		paddingTop: 10,
	},
	topContentRightAlignText: {
		color: '#3C4858',
		fontWeight: 300,
		marginTop: 0,
		marginBottom: 0,
		paddingTop: 10,
		lineHeight: '1.4em',
		fontSize: '1.825em',
		textDecoration: 'none',
		'& small': {
			color: '#777777',
			fontSize: '65%',
			fontWeight: 400,
			lineHeight: 1,
		},
	},
	headerContainer: {
		textAlign: 'center',
		padding: 15,
		marginTop: -20,
		marginRight: 15,
		borderRadius: 3,
		backgroundColor: '#999999',
	},
	iconFloat: {
		float: 'left',
	},
	contentSeparator: {
		borderTop: '1px solid #eeeeee',
		marginTop: 20,
	},
	contentWrapper: {
		//margin: '0 15px 10px',
		//border: 0,
		padding: 0,
		display: 'flex',
		paddingTop: 10,
		alignItems: 'center',
		borderRadius: 0,
		justifyContent: 'space-between',
		backgroundColor: 'transparent',
	},
	content: {
		color: grey[ 700 ],
		display: 'inline-block',
		textAlign: 'left',
		marginBottom: 15,
	},
	fullWidth: {
		width: '100%',
	},
	labelMargin: {
		marginBottom: 16,
	},
	backgroundGrey: {
		background: `linear-gradient(60deg, ${grey[ 500 ]}, ${grey[ 600 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(158, 158, 158, .4)',
	},
	backgroundBlue: {
		background: `linear-gradient(60deg, ${blue[ 400 ]}, ${blue[ 500 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(33, 150, 243, .4)',
	},
	backgroundOrange: {
		background: `linear-gradient(60deg, ${orange[ 400 ]}, ${orange[ 500 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(255, 152, 0, .4)',
	},
	backgroundIndigo: {
		background: `linear-gradient(60deg, ${indigo[ 400 ]}, ${indigo[ 500 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(63, 81, 181, .4)',
	},
	backgroundRed: {
		background: `linear-gradient(60deg, ${red[ 400 ]}, ${red[ 500 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(244, 67, 54, .4)',
	},
	backgroundPink: {
		background: `linear-gradient(60deg, ${pink[ 400 ]}, ${pink[ 500 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(233, 30, 99, .4)',
	},
	backgroundPurple: {
		background: `linear-gradient(60deg, ${purple[ 400 ]}, ${purple[ 500 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(156, 39, 176, .4)',
	},
	backgroundDeepPurple: {
		background: `linear-gradient(60deg, ${deepPurple[ 400 ]}, ${deepPurple[ 500 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(103, 58, 183, .4)',
	},
	backgroundLightBlue: {
		background: `linear-gradient(60deg, ${lightBlue[ 400 ]}, ${lightBlue[ 500 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(3, 169, 244, .4)',
	},
	backgroundCyan: {
		background: `linear-gradient(60deg, ${cyan[ 400 ]}, ${cyan[ 500 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(0, 188, 212, .4)',
	},
	backgroundTeal: {
		background: `linear-gradient(60deg, ${teal[ 400 ]}, ${teal[ 500 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(0, 150, 136, .4)',
	},
	backgroundGreen: {
		background: `linear-gradient(60deg, ${green[ 400 ]}, ${green[ 500 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(76, 175, 80, .4)',
	},
	backgroundLightGreen: {
		background: `linear-gradient(60deg, ${lightGreen[ 500 ]}, ${lightGreen[ 600 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(139, 195, 74, .4)',
	},
	backgroundLime: {
		background: `linear-gradient(60deg, ${lime[ 500 ]}, ${lime[ 600 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(205, 220, 57, .4)',
	},
	backgroundYellow: {
		background: `linear-gradient(60deg, ${yellow[ 500 ]}, ${yellow[ 600 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(255, 255, 59, .4)',
	},
	backgroundAmber: {
		background: `linear-gradient(60deg, ${amber[ 400 ]}, ${amber[ 500 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(255, 193, 7, .4)',
	},
	backgroundDeepOrange: {
		background: `linear-gradient(60deg, ${deepOrange[ 400 ]}, ${deepOrange[ 500 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(255, 87, 34, .4)',
	},
	backgroundBrown: {
		background: `linear-gradient(60deg, ${brown[ 400 ]}, ${brown[ 500 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(121, 85, 72, .4)',
	},
	backgroundBlueGrey: {
		background: `linear-gradient(60deg, ${blueGrey[ 400 ]}, ${blueGrey[ 500 ]})`,
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(96, 125, 139, .4)',
	},
});



const DashboardCard = memo((props) => {
	const { classes, size, color, variant } = props;
	let fullWidth = variant === 'full';

	return (
		<div
			className={classnames({
				[ classes.container ]: true,
				[ classes.containerXSmall ]: size === 'xs',
				[ classes.containerSmall ]: size === 'sm',
				[ classes.containerMedium ]: size === 'md',
				[ classes.containerLarge ]: size === 'lg',
				[ classes.containerXLarge ]: size === 'xl',
			})}
		>
			<div className={classes.innerContainer}>
				<div className={classes.topContentRightAlign}>
					<div
						className={classnames({
							[ classes.headerContainer ]: true,
							[ classes.iconFloat ]: !fullWidth,
							[ classes.fullWidth ]: fullWidth,
							[ classes.labelMargin ]: fullWidth,
							[ classes.backgroundGrey ]: color === 'grey',
							[ classes.backgroundBlue ]: color === 'blue',
							[ classes.backgroundOrange ]: color === 'orange',
							[ classes.backgroundIndigo ]: color === 'indigo',
							[ classes.backgroundRed ]: color === 'red',
							[ classes.backgroundPink ]: color === 'pink',
							[ classes.backgroundPurple ]: color === 'purple',
							[ classes.backgroundDeepPurple ]: color === 'deepPurple',
							[ classes.backgroundLightBlue ]: color === 'lightBlue',
							[ classes.backgroundCyan ]: color === 'cyan',
							[ classes.backgroundTeal ]: color === 'teal',
							[ classes.backgroundGreen ]: color === 'green',
							[ classes.backgroundLightGreen ]: color === 'lightGreen',
							[ classes.backgroundLime ]: color === 'lime',
							[ classes.backgroundYellow ]: color === 'yellow',
							[ classes.backgroundAmber ]: color === 'amber',
							[ classes.backgroundDeepOrange ]: color === 'deepOrange',
							[ classes.backgroundBrown ]: color === 'brown',
							[ classes.backgroundBlueGrey ]: color === 'blueGrey',
						})}
					>
						{!fullWidth &&
						<DashboardCardIconHeader icon={props.icon} />
						}

						{fullWidth && props.headerContent}
					</div>

					{!fullWidth &&
					<div>
						<h3 className={classes.topContentRightAlignText}>
							{props.topContent}
						</h3>
						{Boolean(props.topLabel) &&
						<p className={classes.topContentRightAlignLabel}>
							{props.topLabel}
						</p>
						}
					</div>
					}

					<div className={classnames(classes.contentWrapper, classes.contentSeparator)}>
						<div className={classes.content}>
							{props.children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});


DashboardCard.defaultProps = {
	size: 'xl',
	headerFullWidth: false,
	color: 'grey',
	topContent: '',
	variant: 'icon',
	icon: <div />,
};


DashboardCard.propTypes = {
	size: PropTypes.oneOf([ 'xs', 'sm', 'md', 'lg', 'xl' ]).isRequired,
	topLabel: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
	topContent: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
	headerContent: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
	variant: PropTypes.oneOf([ 'icon', 'full' ]).isRequired,
	icon: PropTypes.PropTypes.node,
	color: PropTypes.oneOf([
		'grey',
		'blue',
		'orange',
		'indigo',
		'red',
		'pink',
		'purple',
		'deepPurple',
		'lightBlue',
		'cyan',
		'teal',
		'green',
		'lightGreen',
		'lime',
		'yellow',
		'amber',
		'deepOrange',
		'brown',
		'blueGrey',
	]).isRequired,
};



export default withStyles(styles)(DashboardCard);