import React from 'react';
import DashboardCard from "components/DashboardCard";
import StorageIcon from '@material-ui/icons/Storage';
import PropTypes from 'prop-types';



const Storage = React.memo((props) => (
	<DashboardCard
		color={'lightBlue'}
		icon={<StorageIcon />}
		topLabel="2.3 of 50.0 GB Used"
		topContent={"Storage"}
		size={props.size}
	>
		Looks fine!
	</DashboardCard>
));


Storage.defaultProps = {
	size: 'sm',
};

Storage.propTypes = {
	size: PropTypes.oneOf([ 'xs', 'sm', 'md', 'lg', 'xl' ]).isRequired,
};


export default Storage;