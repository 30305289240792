import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from 'base/store';



class WrappedRoute extends PureComponent {
	componentWillMount = () => {
		let update = {
			useLayout: this.props.extras.useLayout,
		};

		if(this.props.extras.title !== null) {
			update.title = this.props.extras.title;
		}

		this.props.setLayoutState(update);
	};


	render() {
		const { extras, setLayoutState, ...route } = this.props;

		return (
			<Route {...route} />
		)
	}
}



WrappedRoute.propTypes = {
	exact: PropTypes.bool.isRequired,
	path: PropTypes.string.isRequired,
	component: PropTypes.func.isRequired,
	extras: PropTypes.object.isRequired,
	setLayoutState: PropTypes.func.isRequired,
};



const mapDispatchToProps = dispatch => ({
	setLayoutState: data => dispatch(actions.layout.setState(data)),
});


export default connect(null, mapDispatchToProps)(WrappedRoute);