import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import { actions } from 'base/store';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { push } from 'connected-react-router'
import { indigo, lightGreen, orange, purple } from '@material-ui/core/colors';



const styles = theme => ({
	card: {
		minWidth: 260,
	},
	chipRootDefault: {
		height: 20,
	},
	chipRootGreen: {
		height: 20,
		backgroundColor: lightGreen[ 'A200' ],
	},
	chipRootOrange: {
		height: 20,
		backgroundColor: orange[ 'A200' ],
	},
	chipRootIndigo: {
		height: 20,
		backgroundColor: indigo[ 'A100' ],
	},
	chipRootPurple: {
		height: 20,
		backgroundColor: purple[ 'A100' ],
	},
	capitalize: {
		textTransform: 'capitalize',
	},
});



class AppSearchResults extends PureComponent {

	setRoute = (url) => e => {
		this.props.push(url);
		this.resetSearch();
	};

	resetSearch = () => {
		this.props.setLayoutState({
			appSearchTerm: '',
			appSearchResults: null,
			appSearchPending: false,
		});
	};


	getChipClass = type => {
		const { classes } = this.props;

		switch(type) {
			case 'patient':
				return classes.chipRootGreen;
			case 'client':
				return classes.chipRootOrange;
			case 'report':
				return classes.chipRootIndigo;
			case 'layout':
				return classes.chipRootPurple;
			default:
				return classes.chipRootDefault;
		}
	};


	render() {
		const { results, anchor, classes } = this.props;
		console.log(results);

		if(!results) {
			return null;
		}

		if(!results.length) {
			return (
				<Popover
					open
					anchorEl={anchor.current}
					onClose={this.resetSearch}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<Card className={classes.card}>
						<CardContent>
							<Typography variant="caption" color="textSecondary" gutterBottom>
								Search Results
							</Typography>
							<Typography component="p">
								No results found :(
							</Typography>
						</CardContent>
					</Card>
				</Popover>
			)
		}

		return (
			<Popover
				open
				anchorEl={anchor.current}
				onClose={this.resetSearch}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Card className={classes.card}>
					<CardContent>
						<Typography variant="caption" color="textSecondary" gutterBottom>
							Search Results
						</Typography>

						<List component="nav">

							{results.map((result, index) => (
								<ListItem dense button key={index} onClick={this.setRoute(result.url)}>
									<ListItemText primary={result.label} />
									<ListItemSecondaryAction>
										<Chip
											label={result.type}
											className={classes.capitalize}
											classes={{
												root: this.getChipClass(result.type),
											}}
										/>
									</ListItemSecondaryAction>
								</ListItem>
							))}

						</List>
					</CardContent>
				</Card>
			</Popover>
		);
	}
}



AppSearchResults.propTypes = {
	anchor: PropTypes.object.isRequired,
	results: PropTypes.array,
	setLayoutState: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	push: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
	results: state.layout.appSearchResults,
});


const mapDispatchToProps = dispatch => ({
	setLayoutState: (data) => dispatch(actions.layout.setState(data)),
	push: (url) => dispatch(push(url)),
});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppSearchResults));