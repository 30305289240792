import React, { memo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DashboardCard from "components/DashboardCard";
import Pets from '@material-ui/icons/Pets';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';


const styles = theme => ({
	fullWidth: {
		width: '100%',
	},
});

const sourceRows = Array.from('0123456789abcdefghijk');


const RecentPatients = memo((props) => {

	const pageSize = 4;
	const [ page, setPage ] = useState(0);
	const [ rows, setRows ] = useState(sourceRows.slice(page * pageSize, page * pageSize + pageSize));

	function onChangePage(event, page) {
		setPage(page);
		setRows(sourceRows.slice(page * pageSize, page * pageSize + pageSize));
	}

	return (
		<DashboardCard
			color={'green'}
			icon={<Pets />}
			topContent={"Patients"}
			topLabel="21 with activity in the last 7 days"
			size={props.size}
		>
			<div className={props.classes.fullWidth}>
				<Table className={props.classes.fullWidth} padding="dense">
					<TableHead>
						<TableRow>
							<TableCell align="left">Patient Name</TableCell>
							<TableCell align="left">Client Name</TableCell>
							<TableCell align="right">Age</TableCell>
							<TableCell align="right">Notes</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>

						{rows.map((row, index) => (
							<TableRow key={index}>
								<TableCell component="th" scope="row">
									Taco
								</TableCell>
								<TableCell align="left">
									Matt Kment
								</TableCell>
								<TableCell align="right">17</TableCell>
								<TableCell align="right">A very, very good boy [{row}]</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell colSpan={4}>
							<TablePagination
								rowsPerPageOptions={[ pageSize ]}
								component="div"
								count={sourceRows.length}
								rowsPerPage={pageSize}
								page={page}
								backIconButtonProps={{
									'aria-label': 'Previous Page',
								}}
								nextIconButtonProps={{
									'aria-label': 'Next Page',
								}}
								onChangePage={onChangePage}
							/>
							</TableCell>
						</TableRow>

					</TableFooter>
				</Table>
			</div>


		</DashboardCard>
	)
});



RecentPatients.defaultProps = {
	size: 'sm',
};

RecentPatients.propTypes = {
	size: PropTypes.oneOf([ 'xs', 'sm', 'md', 'lg', 'xl' ]).isRequired,
};


export default withStyles(styles)(RecentPatients);