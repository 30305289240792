import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history';
import thunkMiddleware from "redux-thunk";
import layout, { actions as layoutActions } from './Layout/Layout.redux';


export const history = createBrowserHistory();

export const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	layout,
});


export const actions = {
	layout: layoutActions,
};


const store = createStore(
	createRootReducer(history),
	compose(
		applyMiddleware(
			routerMiddleware(history),
			thunkMiddleware
		),
		//window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(
	),

);


export default store;