import { createMuiTheme } from '@material-ui/core/styles';
import primaryColor from '@material-ui/core/colors/indigo';
import secondaryColor from '@material-ui/core/colors/deepOrange';
import errorColor from '@material-ui/core/colors/red';



const theme = createMuiTheme({
	palette: {
		primary: primaryColor,
		secondary: secondaryColor,
		error: errorColor,
	},
	typography: {
		useNextVariants: true,
	},
	overrides: {
		MuiToolbar: {
			regular: {
				'@media (min-width: 600px)': {
					minHeight: 56,
					maxHeight: 56,
				},
				minHeight: 56,
				maxHeight: 56,
			},
		},
	}
});


export default theme;