import { createMuiTheme } from '@material-ui/core/styles';
import primaryColor from '@material-ui/core/colors/indigo';
import secondaryColor from '@material-ui/core/colors/deepOrange';
import errorColor from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';


const theme = createMuiTheme({
	palette: {
		type: 'dark',
		primary: primaryColor,
		secondary: secondaryColor,
		error: errorColor,
	},
	typography: {
		useNextVariants: true,
	},
	overrides: {
		MuiListItemIcon: {
			root: {
				color: grey[300],
			},
		},
		MuiListItem: {
			gutters: {
				paddingLeft: 18,
				paddingRight: 16,
				paddingTop: 12,
				paddingBottom: 12,
				marginLeft: 8,
				borderRadius: 8,
				width: 'calc(100% - 16px)',
			}
		}
	},
});


export default theme;