import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import Fullscreen from '@material-ui/icons/Fullscreen';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import Navigation from './Navigation';
import { connect } from 'react-redux';
import { actions } from 'base/store';
import AppSearch from './AppSearch';



const drawerWidth = 240;

const styles = theme => ({
	root: {
		//display: 'flex',
	},
	appBarBackground: {
		background: '#ffffff',
	},
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
		[ theme.breakpoints.up('lg') ]: {
			display: 'none',
		},
	},
	title: {
		display: 'none',
		[ theme.breakpoints.up('sm') ]: {
			display: 'block',
		},
	},
	sectionDesktop: {
		display: 'none',
		[ theme.breakpoints.up('md') ]: {
			display: 'flex',
		},
	},
	sectionMobile: {
		display: 'flex',
		[ theme.breakpoints.up('md') ]: {
			display: 'none',
		},
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarOpen: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth - 1}px)`,
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	appBarClosed: {
		marginLeft: theme.spacing.unit * 10,
		width: `calc(100% - ${theme.spacing.unit * 10 - 1}px)`,
		[ theme.breakpoints.down('md') ]: {
			marginLeft: 0,
			width: '100%',
		},
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxShadow: '0 1px 24px 0 rgba(0, 0, 0, 0.25)',
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing.unit * 10,
		[ theme.breakpoints.down('md') ]: {
			width: 0,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3,
		width: `calc(100% - ${theme.spacing.unit * 10}px)`,
		marginLeft: theme.spacing.unit * 10,
		[ theme.breakpoints.down('md') ]: {
			width: '100%',
			marginLeft: 0,
		},
	},
});



class Layout extends React.Component {
	state = {
		anchorEl: null,
		mobileMoreAnchorEl: null,
	};


	handleProfileMenuOpen = event => {
		this.setState({ anchorEl: event.currentTarget });
	};


	handleMenuClose = () => {
		this.setState({ anchorEl: null });
		this.handleMobileMenuClose();
	};


	handleMobileMenuOpen = event => {
		this.setState({ mobileMoreAnchorEl: event.currentTarget });
	};


	handleMobileMenuClose = () => {
		this.setState({ mobileMoreAnchorEl: null });
	};


	toggleFullscreen = event => {
		let element = document.body;
		if(event instanceof HTMLElement) {
			element = event;
		}

		let isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;
		element.requestFullScreen = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || function() {
			return false;
		};
		document.cancelFullScreen = document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || function() {
			return false;
		};

		isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
	};


	renderMenu = () => (
		<Menu
			anchorEl={this.state.anchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={Boolean(this.state.anchorEl)}
			onClose={this.handleMenuClose}
		>
			<MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
			<MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
		</Menu>
	);


	renderMobileMenu = () => {
		const { mobileMoreAnchorEl } = this.state;
		const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

		return (
			<Menu
				anchorEl={mobileMoreAnchorEl}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={isMobileMenuOpen}
				onClose={this.handleMobileMenuClose}
			>
				<MenuItem>
					<IconButton color="inherit">
						<Badge badgeContent={4} color="secondary">
							<MailIcon />
						</Badge>
					</IconButton>
					<p>Messages</p>
				</MenuItem>
				<MenuItem>
					<IconButton color="inherit">
						<Badge badgeContent={11} color="secondary">
							<NotificationsIcon />
						</Badge>
					</IconButton>
					<p>Notifications</p>
				</MenuItem>
				<MenuItem onClick={this.handleProfileMenuOpen}>
					<IconButton color="inherit">
						<AccountCircle />
					</IconButton>
					<p>Profile</p>
				</MenuItem>
			</Menu>
		)
	};



	render() {
		const { classes, menuOpen, toggleMenu, title, children, useLayout } = this.props;

		if(!useLayout) {
			return <React.Fragment>{children}</React.Fragment>;
		}

		const { anchorEl } = this.state;
		const isMenuOpen = Boolean(anchorEl);


		return (
			<div className={classes.root}>
				<AppBar
					position="fixed"
					color="default"
					classes={{ colorDefault: classes.appBarBackground }}
					elevation={2}
					className={classNames(classes.appBar, {
						[ classes.appBarOpen ]: menuOpen,
						[ classes.appBarClosed ]: !menuOpen,
					})}
				>
					<Toolbar>
						<Tooltip title="Toggle Menu">
							<IconButton
								color="inherit"
								aria-label="Open drawer"
								onClick={toggleMenu}
								className={classes.menuButton}
							>
								{menuOpen ? <ChevronLeft /> : <MenuIcon />}
							</IconButton>
						</Tooltip>

						<Typography className={classes.title} variant="h6" color="inherit" noWrap>
							{title}
						</Typography>

						<div className={classes.grow} />
						<AppSearch />
						<div>
							<Tooltip title="Toggle Fullscreen">
								<IconButton color="inherit" onClick={this.toggleFullscreen}>
									<Fullscreen />
								</IconButton>
							</Tooltip>
						</div>
						<div className={classes.sectionDesktop}>
							<Tooltip title="Notifications">
								<IconButton color="inherit">
									<Badge badgeContent={17} color="secondary">
										<NotificationsIcon />
									</Badge>
								</IconButton>
							</Tooltip>

							<Tooltip title="Account Options">
								<IconButton
									aria-owns={isMenuOpen ? 'material-appbar' : undefined}
									aria-haspopup="true"
									onClick={this.handleProfileMenuOpen}
									color="inherit"
								>
									<AccountCircle />
								</IconButton>
							</Tooltip>
						</div>
						<div className={classes.sectionMobile}>
							<IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
								<MoreIcon />
							</IconButton>
						</div>
					</Toolbar>
				</AppBar>
				{this.renderMenu()}
				{this.renderMobileMenu()}

				<Drawer
					variant="permanent"
					className={classNames(classes.drawer, {
						[ classes.drawerOpen ]: menuOpen,
						[ classes.drawerClose ]: !menuOpen,
					})}
					classes={{
						paper: classNames({
							[ classes.drawerOpen ]: menuOpen,
							[ classes.drawerClose ]: !menuOpen,
						}),
					}}
					open={menuOpen}
				>
					<Navigation />
				</Drawer>

				<main className={classes.content}>
					<div className={classes.toolbar} />
					{children}
				</main>
			</div>
		);
	}
}



Layout.propTypes = {
	classes: PropTypes.object.isRequired,
};


const mapDispatchToProps = dispatch => ({
	toggleMenu: () => dispatch(actions.layout.toggleMenu()),
});


const mapStateToProps = state => ({
	menuOpen: state.layout.menuOpenTemporary || state.layout.menuOpen,
	title: state.layout.title,
	useLayout: state.layout.useLayout,
});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Layout));