import createRoute from './createRoute';
import Home from 'pages/Home';
import HomeTwo from 'pages/HomeTwo';



export const routes = [

	createRoute({
		path: '/',
		exact: true,
		component: Home,
		title: 'Home',
	}),

	createRoute({
		path: '/test',
		exact: true,
		component: HomeTwo,
		title: 'Test',
	}),

];