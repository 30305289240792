import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'base/store'
import { routes } from 'base/routes';
import WrappedRoute from './WrappedRoute';
import Layout from 'base/Layout';



const Router = (props) => (
	<ConnectedRouter history={history}>
		<Route
			render={({ location }) => (
				<Layout>
					<Switch
						location={location}
						key={location.key}
					>
						{routes.map(config => {
							return <WrappedRoute {...config.route} extras={config.extras} key={config.route.path} />
						})}
					</Switch>
				</Layout>
			)}
		/>
	</ConnectedRouter>
);




export default Router;