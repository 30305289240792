import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classnames from 'classnames';



const styles = theme => ({
	icon: {
		fontSize: '32px !important',
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
});



const DashboardCardIconHeader = React.memo((props) => {
	const { classes, icon } = props;
	let clone = React.cloneElement(icon, {
		className: classnames({
			[ classes.icon ]: true
		}),
	});


	return (
		<div>
			<span className={classes.message}>
				{clone}
			</span>
		</div>
	);

});


DashboardCardIconHeader.propTypes = {
	icon: PropTypes.PropTypes.node.isRequired,
};

export default withStyles(styles)(DashboardCardIconHeader);